
  import { defineComponent, ref } from "vue";
  import Globals from "../../views/common/Globals.vue";
  import ApiService from "../../core/services/ApiService";
  import { ElNotification, ElLoading } from "element-plus";
  export default defineComponent({
    name: "FilterEvent",
    mixins: [Globals],
  
    props: {
  
    },
    data() {
  
  
    
      return {
        gatewayNames:[] as any,
        productGroup: [] as any,
        selectedGateway:"",
        selectedProductGroup:""


      }
    },
    mounted() {
        this.getMasterApi();  
    },
    components: {},
    methods: {    
         //remote method search
    remoteMethod(query: string) {
      const test = query;
    },
      //Apply Filter
      applyFilter() {
          this.$emit("setFilter",{gateWayName:this.selectedGateway,productGroupName:this.selectedProductGroup})
      },
      //cleare Filter
      cleareFilter() {
 
          this.selectedGateway = '',
          this.selectedProductGroup = ''
  
      },
       //getApi Provider Method
       getMasterApi() {
        ApiService.get(`/master?organizationId=1SB`).then((data) => {
            const {APIGroup} = data.data.data;
            this.productGroup = APIGroup;

        }).catch((error) => {
          console.log("Error Inside Organization", error)
        })
      }
      } 
    
  
  });
  