
import { defineComponent, ref, onMounted } from "vue";
import SMSConfiguration from "@/views/communicationService/smsConfiguration.vue";
import EmailConfiguration from "@/views/communicationService/emailConfiguration.vue";
import EventDataTable from "@/components/kt-datatable/EventDataTable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElLoading, ElNotification } from 'element-plus'
import { EVENTS, STANDARD_VARIABLE, TEMPLATES } from "@/helper/globalUrl.ts";
import Globals from "@/views/common/Globals.vue";
import Channel from "./channel.vue";
import FilterEvent from './EventFilter.vue'

export default defineComponent({
  name: "Event",
  components: {
    EventDataTable,
    SMSConfiguration,
    EmailConfiguration,
    FilterEvent
  },
  mixins: [Globals],
  data() {
    return {
      page: 1,
      pageSize: 25,
      totalCount: 1,
      selectedProductGroup: '',
      selectedGateway: '',
      eventData: [] as any,
      channelStatus: {
        "FD": {
          email: false,
          sms: false,
          whatsapp: false,
        },
        "INS": {
          email: false,
          sms: false,
          whatsapp: false,
        },
        "PMS": {
          email: false,
          sms: false,
          whatsapp: false,
        }
      },
      render: false,
      smsModalFlag: false,
      emailModalFlag: false,
      emailTemplate: [] as any,
      smsTemplate: [] as any,
      standardVariable: [] as any,
      initEvents: [] as any,
      search: "",
      searchFlag: true,
      gatewayName: ''
    };
  },
  mounted() {
    let channelJson = JSON.parse(localStorage.getItem("channel"))
    this.channelStatus.FD.email = channelJson['FD']["email"]
    this.channelStatus.FD.sms = channelJson['FD']["sms"]
    this.channelStatus.FD.whatsapp = channelJson['FD']["whatsapp"]
    this.channelStatus.INS.email = channelJson['INS']["email"]
    this.channelStatus.INS.sms = channelJson['INS']["sms"]
    this.channelStatus.INS.whatsapp = channelJson['INS']["whatsapp"]
    this.channelStatus.PMS.email = channelJson['PMS']["email"]
    this.channelStatus.PMS.sms = channelJson['PMS']["sms"]
    this.channelStatus.PMS.whatsapp = channelJson['PMS']["whatsapp"]

    // this.getEvent();
    this.getEvents();
    //  this.getStandardVariable()
  },
  methods: {
    isChannelDisabled(event_data, channelName){
      if (event_data['apiGroup'] && event_data['apiGroup'] != ""){
        if (channelName == 'email'){
          return !this.channelStatus[event_data['apiGroup']].email
        }else{
          // debugger;
          return !this.channelStatus[event_data['apiGroup']].sms
        }
      }else{
        if (channelName == 'email'){
          return !this.channelStatus[event_data['gatewayName']].email
        }else{
          // debugger;
          return !this.channelStatus[event_data['gatewayName']].sms
        }
      }
    },
    //search Item
    searchItems() {
      this.eventData.splice(
        0,
        this.eventData.length,
        ...this.initEvents
      );

      if (this.search !== "") {
        let results = []
        for (let j = 0; j < this.eventData.length; j++) {
          if (this.searchingFunc(this.eventData[j], this.search)) {
            results.push(this.eventData[j]);
          }

        }
        this.eventData.splice(
          0,
          this.eventData.length,
          ...results
        );
        if (this.eventData.length == 0 && this.searchFlag == true) {
          this.searchFlag = false
          ElNotification({
            title: 'Warning',
            message: 'No Records Found!',
            type: 'warning',
            duration: 2000,
            position: 'top-right'
          })
        }
        else if (this.eventData.length != 0) {
          this.searchFlag = true
        }
      }
    },
    //Searching Function
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object") && !(typeof obj[key] === "boolean")) {
          if (obj[key] != "") {
            if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
              return true;
            }
          }
        }
      }
      return false;
    },
    //setCountPage And call getEvents
    setCountPage(data) {
      const { count, page } = data;
      this.page = page;
      this.pageSize = count;
      this.getEvents()
    },
    //Method for SetFilter
    setFilter(filteredData) {
      this.selectedGateway = filteredData.gateWayName;
      this.selectedProductGroup = filteredData.productGroupName;
      this.getEvents();
    },
    //Add channel for Events
    addEmailTempletConfiguration(event, defaultEmailTemplate, eventID) {
      const formData: any = {
        PCSConfigurationId: 2,
        eventId: eventID,
        isEnabled: event.target.checked,
        eventTemplate: defaultEmailTemplate || {}
      }
      ApiService.post(TEMPLATES + "template", formData).then((data) => {
        ElNotification({
              title: "Success",
              message: "Added Successfully!",
              type: "success",
              position: "top-right",
            });
        this.getEvents();
      }).catch((error) => {
        console.log("Error In ADD Template", error)
      })
      //       {
      //   "PCSConfigurationId": "<integer>",
      //   "eventId": "<integer>",
      //   "isEnabled": "<boolean>",
      //   "eventTemplate": "<object>"
      // }
    },
    addSmsTempletConfiguration(event, defaultSMSTemplate, eventID) {
      const formData: any = {
        PCSConfigurationId: 1,
        eventId: eventID,
        isEnabled: event.target.checked,
        eventTemplate: defaultSMSTemplate || {}
      }
      ApiService.post(TEMPLATES + "template", formData).then((data) => {
        ElNotification({
              title: "Success",
              message: "Added Successfully!",
              type: "success",
              position: "top-right",
            });
        this.getEvents();
      }).catch((error) => {
        console.log("Error In ADD Template", error)
      })
    },
    //disabel Enable
    changeSmsTempletConfiguration(event, templateId, eventTemplate,defaultSmsTemplate) {
      const formData: any = {
        isEnabled: event.target.checked,
        eventTemplate: eventTemplate ? eventTemplate : defaultSmsTemplate
      }
      ApiService.patch(EVENTS + templateId, formData).then((data) => {
        ElNotification({
          title: "Success",
          message: "Updated Successfully!",
          type: "success",
          position: "top-right",
        });
      })
        .catch((error) => {
          console.log("Error in Edit Config", error)
        })
    },
    changeEmailTempletConfiguration(event, templateId, eventTemplate,defaultEmailTemplate) {
      const formData: any = {
        isEnabled: event.target.checked,
        eventTemplate: eventTemplate ? eventTemplate : defaultEmailTemplate
      }
      ApiService.patch(EVENTS + templateId, formData).then((data) => {
        ElNotification({
          title: "Success",
          message: "Updated Successfully!",
          type: "success",
          position: "top-right",
        });
      })
        .catch((error) => {
          console.log("Error in Edit Config", error)
        })
    },
    //getEvents
    getEvents() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(`${EVENTS + this.globalData.organizationId}?pageNo=${this.page}&pageSize=${this.pageSize}&eventName=&gatewayName=${this.selectedGateway}&apiGroup=${this.selectedProductGroup}`).then((data) => {
        this.eventData = data.data.event_list;
        this.totalCount = data.data.total_count;
        this.render = true;
        this.initEvents.length = 0;
        loading.close();
        this.initEvents.splice(
          0,
          this.eventData.length,
          ...this.eventData
        );
        this.search = "";
        console.log("Data", data.data)
      });
    },
    validateEvent(eventId, e1, e2, e3, channel, formData) {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      formData.distributorId = this.globalData.organizationId
      formData.eventsInfo[0].eventId = eventId
      formData.eventsInfo[0].channels.email = e1
      formData.eventsInfo[0].channels.sms = e2
      formData.eventsInfo[0].channels.whatsapp = e3
      this.render = false
      ApiService.patch(EVENTS, formData).then((data) => {
        this.updateLocalEventData(eventId, e1, e2, e3, channel);
        loading.close()
      })
        .catch((error) => {
          if (JSON.stringify(error.response.status) === "404") {
            ApiService.post(EVENTS, formData)
              .then((data) => {
                this.updateLocalEventData(eventId, e1, e2, e3, channel);
                loading.close()
              })
              .catch((error) => {
                console.log(error);
                loading.close()
              });
          }
          loading.close();
        });
    },
    updateLocalEventData(eventId, e1, e2, e3, channel) {
      for (let i = 0; i < this.eventData.length; i++) {
        if (this.eventData[i]["eventId"] == eventId) {
          this.eventData[i]["channel"]["email"] = e1
          this.eventData[i]["channel"]["sms"] = e2
          this.eventData[i]["channel"]["whatsApp"] = e3
          break
        }
      }
      this.render = true
    },
    async getStandardVariable(gateway) {
      await ApiService.get(`${STANDARD_VARIABLE}/${gateway}?apiGroup=`).then((data) => { 
        // console.log("Standerd Variable",data.data)
        // console.table(data.data)
        this.standardVariable = JSON.parse(JSON.stringify(data.data));
        localStorage.setItem("standard_variable", JSON.stringify(this.standardVariable));
        console.log("Standard variable is set:", JSON.stringify(this.standardVariable))
      })
        .catch((error) => {
          console.log("Error While getting standard variable: ", error);
        });
    },
    //PassEvents To Email
    async passEmailEvents(eventId, eventName, gateway, eventTemplate,eventTemplateId) {
      // this.gatewayName = gateway;
      // console.log("Inside EmailEvent", eventId, eventName, gateway, eventTemplate)
      await this.getStandardVariable(gateway);
      console.log("in passEmailEvents standardVariable-->>", this.standardVariable);
      (this.$refs["passEventToEmail"] as any).callEmailConfigureModal(eventId, eventName, eventTemplate, this.standardVariable,eventTemplateId);
    },
      //PassEvents To Sms
    async passSmsEvents(eventId, eventName, gateway, eventTemplate,eventTemplateId) {
      // this.gatewayName = gateway;
      // console.log("Inside EmailEvent", eventId, eventName, gateway, eventTemplate)

      await this.getStandardVariable(gateway);
      (this.$refs["passEventToSMS"] as any).callSMSConfigureModal(eventId, eventName, eventTemplate, this.standardVariable,eventTemplateId);
    },
    getEvent() {
      // if (!localStorage.getItem("standard_variable")) {
      //   this.getStandardVariable();
      // }
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(EVENTS + "?organizationId=" + this.globalData.organizationId).then((data) => {
        this.eventData = JSON.parse(JSON.stringify(data.data.data));
        for (let i = 0; i < this.eventData.length; i++) {
          if (this.eventData[i]["channel"]["email"] == "ENABLED")
            this.eventData[i]["channel"]["email"] = true
          else if (this.eventData[i]["channel"]["email"] == "DISABLED")
            this.eventData[i]["channel"]["email"] = false

          if (this.eventData[i]["channel"]["sms"] == "ENABLED")
            this.eventData[i]["channel"]["sms"] = true
          else if (this.eventData[i]["channel"]["sms"] == "DISABLED")
            this.eventData[i]["channel"]["sms"] = false

          if (this.eventData[i]["channel"]["whatsApp"] == "ENABLED")
            this.eventData[i]["channel"]["whatsApp"] = true
          else if (this.eventData[i]["channel"]["whatsApp"] == "DISABLED")
            this.eventData[i]["channel"]["whatsApp"] = false
        }
        console.log(this.eventData, "allData");
        this.render = true
        loading.close();
      })
        .catch((error) => {
          console.log(error);
          loading.close()
        });
    },
    passEventToEmailConfiguration(eventId, eventName) {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(TEMPLATES + "?organizationId=" + this.globalData.organizationId + "&eventId=" + eventId).then((data) => {
        loading.close()
        this.emailTemplate = JSON.parse(JSON.stringify(data.data.data.templates));
        (this.$refs["passEventToEmail"] as any).callEmailConfigureModal(eventId, eventName, this.emailTemplate, this.standardVariable);

      })
        .catch((error) => {
          console.log(error);
          loading.close()
        });

    },
    passEventToSMSConfiguration(eventId, eventName, gateway) {
      // this.gatewayName = gateway;
      // this.getStandardVariable();
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(TEMPLATES + "?organizationId=" + this.globalData.organizationId + "&eventId=" + eventId).then((data) => {
        this.smsTemplate = JSON.parse(JSON.stringify(data.data.data.templates));
        (this.$refs["passEventToSMS"] as any).callSMSConfigureModal(eventId, eventName, this.smsTemplate, this.standardVariable);
        loading.close()
      })
        .catch((error) => {
          console.log(error);
          loading.close()
        });
    }
  },

  setup() {
    const formData = ref({
      distributorId: "",
      eventsInfo: [
        {
          "eventId": 0,
          "channels": {
            "email": false,
            "sms": false,
            "whatsapp": false
          }
        }
      ]

    })
    const tableHeader = ref([
      {
        name: "Event Name",
        key: "eventName",
        sortable: true,
      },
      {
        name: "Event Description",
        key: "eventDescription",
      },
      {
        name: "Gateway Name",
        key: "gatewayName"
      },
      {
        name: "Product Group",
        key: "productGroup"
      },

      {
        name: "Email",
        key: "email",
        sortable: true,

      },

      {
        name: "Sms",
        key: "sms",
        sortable: true,

      },

      {
        name: "Whatsapp",
        key: "whatsApp",
        sortable: true,

      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Event Configuration", ["Setting"]);
    });

    return {
      tableHeader,
      formData
    };
  },
});
